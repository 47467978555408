import React from "react"
import PropTypes from "prop-types"

const EntityCardImage = props => {
  const {
    className,
    image,
    link,
    onClick,
    isLink,
    name,
    course,
    address,
    fax,
    phone,
    email,
    www,
  } = props

  const renderElement = () => {
    if (!isLink) {
      return (
        <button
          className={`EntityCardImage d-flex ${className}`}
          onClick={handleClick}
        >
          <img src={image} alt={name} />
        </button>
      )
    }

    const validLink = /^http/
    const href = link?.match(validLink) ? link : `https://${link}`

    return (
      <a
        className={`EntityCardImage ${className}`}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        title={name}
      >
        <img src={image} alt={name} />
      </a>
    )
  }

  const handleClick = () => {
    if (onClick && typeof onClick === "function") {
      onClick({
        image,
        name,
        course,
        address,
        fax,
        phone,
        email,
        www,
      })
    }
  }

  return renderElement()
}

EntityCardImage.propTypes = {
  className: PropTypes.string,
  image: PropTypes.string.isRequired,
  link: PropTypes.string,
  onClick: PropTypes.func,
  isLink: PropTypes.bool,
  information: PropTypes.object,
}

EntityCardImage.defaultProps = {
  className: "",
  link: "",
  isLink: false,
  information: {},
}

export default EntityCardImage
