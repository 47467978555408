import React, { useEffect, useState } from "react"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Breadcrumb from "../components/Breadcrumb"
import TitleBlock from "../components/TitleBlock"
import EntityCardImage from "../components/EntityCardImage"
import { description } from "../data/efa-course-data"
import parse from "react-html-parser"
import EntityModal from "../components/EntityModal"
import { useAxiosClient } from "../service/useAxiosClient"
import TitlePage from "../components/TitlePage"
import DownloadBtn from "../components/DownloadBtn"

const defaultImage = "/imgs/default-image.jpg"

export default function AccreditedTraining({ location }) {
  const [openModal, setOpenModal] = useState(false)
  const [infoCard, setInfoCard] = useState({})
  const [efaList, setEfaList] = useState([])
  const [efpList, setEfpList] = useState([])
  const [esgList, setEsgList] = useState([])

  const [{ data: dataEfa }] = useAxiosClient({
    url: `enterprises/centers/?type=efa`,
    method: "GET",
  })

  useEffect(() => {
    if (dataEfa && dataEfa.length) {
      setEfaList(dataEfa)
    }
  }, [dataEfa])

  const [{ data: dataEfp }] = useAxiosClient({
    url: `enterprises/centers/?type=efp`,
    method: "GET",
  })

  useEffect(() => {
    if (dataEfp && dataEfp.length) {
      setEfpList(dataEfp)
    }
  }, [dataEfp])

  const [{ data: dataEsg }] = useAxiosClient({
    url: `enterprises/centers/?type=esg`,
    method: "GET",
  })

  useEffect(() => {
    if (dataEsg && dataEsg.length) {
      setEsgList(dataEsg)
    }
  }, [dataEsg])

  const path = [
    { label: "Inicio", to: "/" },
    {
      label: "Certificaciones - Cursos preparatorios para los exámenes",
    },
  ]
  const toggleModal = state => {
    setOpenModal(state)
  }

  const loadModal = infoCard => {
    setInfoCard(infoCard)
    setOpenModal(true)
  }

  const getUrl = (list, type) => {
    let url = {}
    for (const key in list) {
      if (key === type) {
        url = { label: list[key], link: list[key] }
      }
    }
    return url
  }

  const renderCardsEFA = (list, type) => {
    return (
      list &&
      list.map(item => (
        <EntityCardImage
          className="Entity-card"
          image={item?.logo || defaultImage}
          onClick={loadModal}
          isLink={false}
          name={item?.name}
          course={item?.course}
          address={item?.address}
          phone={item?.phone}
          fax={item?.fax}
          email={item?.email}
          www={getUrl(item?.www, type)}
        />
      ))
    )
  }

  const renderCardsEFP = (list, type) => {
    return (
      list &&
      list.map(item => (
        <EntityCardImage
          className="Entity-card"
          image={item?.logo || defaultImage}
          onClick={loadModal}
          isLink={false}
          name={item?.name}
          course={item?.course}
          address={item?.address}
          phone={item?.phone}
          fax={item?.fax}
          email={item?.email}
          www={getUrl(item?.www, type)}
        />
      ))
    )
  }

  const renderCardsESG = (list, type) => {
    return (
      list &&
      list.map(item => (
        <EntityCardImage
          className="Entity-card"
          image={item?.logo || defaultImage}
          onClick={loadModal}
          isLink={false}
          name={item?.name}
          course={item?.course}
          address={item?.address}
          phone={item?.phone}
          fax={item?.fax}
          email={item?.email}
          www={getUrl(item?.www, type)}
        />
      ))
    )
  }

  return (
    <Layout>
      <TitlePage
        title="Centros formativos acreditados EFA"
        url={location.pathname}
      />
      <Hero image="/imgs/certificaciones-hero.jpg" text="Certificaciones" />
      <div className="container950">
        <div className="EfaCourse">
          <Breadcrumb path={path} />
          <div className="efpa-content">
            <TitleBlock
              title="Cursos preparatorios para los exámenes"
              icon="fas fa-user-shield"
            />
            <div className="EfaCourse-description">{parse(description)}</div>
            <DownloadBtn
              link="/docs/manual_centros_acreditados_efa.pdf"
              text="Si deseas ser centro acreditado por EFPA España"
            />
          </div>
          <div className="efpa-content">
            <h2 className="TitleBlock EfaCourse-subtitle">
              <i className="fas fa-home"></i>
              Centros formativos acreditados EFA -{" "}
              <span className="EfaCourse-italic">
                European Financial Advisor
              </span>
            </h2>
            <div className="row efpa-row mb-4">
              {renderCardsEFA(efaList, "efa")}
            </div>
          </div>
          <div className="efpa-content">
            <h2 className="TitleBlock EfaCourse-subtitle">
              <i className="fas fa-home"></i>
              Centros formativos acreditados EFP -{" "}
              <span className="EfaCourse-italic">
                European Financial Planner
              </span>
            </h2>
            <div className="row efpa-row mb-4">
              {renderCardsEFP(efpList, "efp")}
            </div>
          </div>
          <h2 className="TitleBlock EfaCourse-subtitle">
            <i className="fas fa-home"></i>
            Centros formativos acreditados EFPA ESG{" "}
            <span className="EfaCourse-italic">Advisor</span>
          </h2>
          <div className="row efpa-row">{renderCardsESG(esgList, "esg")}</div>
        </div>
      </div>
      <EntityModal
        isOpen={openModal}
        toggle={toggleModal}
        image={infoCard.image}
        address={infoCard.address}
        course={infoCard.course}
        fax={infoCard.fax}
        mail={infoCard.email}
        name={infoCard.name}
        tel={infoCard.phone}
        website={infoCard.www}
        linksBlank
      />
    </Layout>
  )
}
