import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Modal, ModalHeader, ModalBody } from "reactstrap"
import { Link } from "gatsby"

const EntityModal = props => {
  const {
    name,
    address,
    tel,
    fax,
    mail,
    website,
    isOpen: isOpenProps,
    toggle,
    linksBlank,
  } = props
  const [isOpen, setIsOpen] = useState(false || isOpenProps)

  const toggleModal = () => {
    setIsOpen(!isOpen)

    if (toggle && typeof toggle === "function") {
      toggle(!isOpen)
    }
  }

  useEffect(() => {
    setIsOpen(isOpenProps)
  }, [isOpenProps])

  const closeBtn = (
    <button className="close" onClick={toggleModal}>
      X
    </button>
  )

  const renderContactInfo = () => {
    let contacts = []
    if (tel) {
      contacts = [<span className="EntityModal-tel">Telefono: {tel}</span>]
    }
    if (fax) {
      contacts = [
        ...contacts,
        <span className="EntityModal-fax">Fax: {fax}</span>,
      ]
    }
    if (mail) {
      contacts = [...contacts, <span className="EntityModal-mail">{mail}</span>]
    }
    if (website) {
      const linkProps = linksBlank
        ? { target: "_blank", rel: "noopener noreferrer" }
        : {}
      contacts = [
        ...contacts,
        <span className="EntityModal-website">
          <Link to={website.link} {...linkProps}>
            Visitar Website
          </Link>
        </span>,
      ]
    }

    return contacts
  }

  const renderAddress = () => {
    let addresses = []
    for (const key in address) {
      if (address[key]) {
        addresses = [
          ...addresses,
          <span className="EntityModal-address">{address[key]}</span>,
        ]
      }
    }
    return addresses
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleModal}
      className="EntityModal"
      centered
      scrollable
    >
      <ModalHeader className="EntityModal-header" close={closeBtn}>
        <span className="EntityModal-title">{name || "null"}</span>
      </ModalHeader>
      <ModalBody>
        <div className="EntityModal-body">
          <div className="row">
            <div className="col-12 col-md-6 p-0">{renderAddress()}</div>
            <div className="col-12 col-md-6 p-0">
              <div className="EntityModal-contact">{renderContactInfo()}</div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

EntityModal.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  image: PropTypes.func,
  website: PropTypes.string,
  name: PropTypes.string.isRequired,
  address: PropTypes.string,
  tel: PropTypes.string,
  fax: PropTypes.string,
  mail: PropTypes.string,
  description: PropTypes.string.isRequired,
  linksBlank: PropTypes.bool,
}

export default EntityModal
