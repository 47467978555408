export const description = `
  <p>Desde la asociación no se imparte la formación preparatoria para los exámenes pero sí se dispone de centros de formación acreditados, que han sido previamente evaluados por el Comité de Acreditación y Certificación. Éste aprueba que tanto el programa que desarrollan, como su nivel de profesorado y manual formativo, cumple con los requisitos de formación y cumplimiento de los programas exigidos a nivel europeo.</p>
  <p>Los centros de formación acreditados, disponen del sello de certificación de EFPA España y están autorizados para utilizar las marcas registradas en la promoción de sus cursos.</p>
`
export const efpDescription = `
<p>Desde la asociación no se imparte la formación preparatoria para los exámenes pero sí se dispone de centros de formación acreditados, que han sido previamente evaluados por el Comité de Acreditación y Certificación. Éste aprueba que tanto el programa que desarrollan, como su nivel de profesorado y manual formativo, cumple con los requisitos de formación y cumplimiento de los programas exigidos a nivel europeo.</p>
<p>Los centros de formación acreditados, disponen del sello de certificación de EFPA España y están autorizados para utilizar las marcas registradas en la promoción de sus cursos.</p>
`
export const cardModals = [
  {
    image: "/imgs/efpa.jpg",
    isLink: false,
    information: {
      name: "IEF, Institut d'Estudis Financers",
      address: "Avda. Josep Tarradellas 123-127 08029 Barcelona",
      tel: "934 124 431",
      fax: "934 121 015",
      mail: "ief@iefweb.org",
      website: {
        label: "Visitar Website",
        link:
          "http://www.iefweb.org/es/cursos/master-y-posgrados/programa-superior-de-gestion-patrimonial/",
      },
      description:
        "Programa Asesoramiento Financiero (SEMIPRESENCIAL) Programa Sup. en Gestión Patrimonial (PRESENCIAL)",
    },
  },
  {
    image: "/imgs/efpa.jpg",
    isLink: false,
    information: {
      name: "IEF, Institut d'Estudis Financers",
      address: "Avda. Josep Tarradellas 123-127 08029 Barcelona",
      tel: "934 124 431",
      fax: "934 121 015",
      mail: "ief@iefweb.org",
      website: {
        label: "Visitar Website",
        link:
          "http://www.iefweb.org/es/cursos/master-y-posgrados/programa-superior-de-gestion-patrimonial/",
      },
      description:
        "Programa Asesoramiento Financiero (SEMIPRESENCIAL) Programa Sup. en Gestión Patrimonial (PRESENCIAL)",
    },
  },
  {
    image: "/imgs/efpa.jpg",
    isLink: false,
    information: {
      name: "IEF, Institut d'Estudis Financers",
      address: "Avda. Josep Tarradellas 123-127 08029 Barcelona",
      tel: "934 124 431",
      fax: "934 121 015",
      mail: "ief@iefweb.org",
      website: {
        label: "Visitar Website",
        link:
          "http://www.iefweb.org/es/cursos/master-y-posgrados/programa-superior-de-gestion-patrimonial/",
      },
      description:
        "Programa Asesoramiento Financiero (SEMIPRESENCIAL) Programa Sup. en Gestión Patrimonial (PRESENCIAL)",
    },
  },
  {
    image: "/imgs/efpa.jpg",
    isLink: false,
    information: {
      name: "IEF, Institut d'Estudis Financers",
      address: "Avda. Josep Tarradellas 123-127 08029 Barcelona",
      tel: "934 124 431",
      fax: "934 121 015",
      mail: "ief@iefweb.org",
      website: {
        label: "Visitar Website",
        link:
          "http://www.iefweb.org/es/cursos/master-y-posgrados/programa-superior-de-gestion-patrimonial/",
      },
      description:
        "Programa Asesoramiento Financiero (SEMIPRESENCIAL) Programa Sup. en Gestión Patrimonial (PRESENCIAL)",
    },
  },
  {
    image: "/imgs/efpa.jpg",
    isLink: false,
    information: {
      name: "IEF, Institut d'Estudis Financers",
      address: "Avda. Josep Tarradellas 123-127 08029 Barcelona",
      tel: "934 124 431",
      fax: "934 121 015",
      mail: "ief@iefweb.org",
      website: {
        label: "Visitar Website",
        link:
          "http://www.iefweb.org/es/cursos/master-y-posgrados/programa-superior-de-gestion-patrimonial/",
      },
      description:
        "Programa Asesoramiento Financiero (SEMIPRESENCIAL) Programa Sup. en Gestión Patrimonial (PRESENCIAL)",
    },
  },
  {
    image: "/imgs/efpa.jpg",
    isLink: false,
    information: {
      name: "IEF, Institut d'Estudis Financers",
      address: "Avda. Josep Tarradellas 123-127 08029 Barcelona",
      tel: "934 124 431",
      fax: "934 121 015",
      mail: "ief@iefweb.org",
      website: {
        label: "Visitar Website",
        link:
          "http://www.iefweb.org/es/cursos/master-y-posgrados/programa-superior-de-gestion-patrimonial/",
      },
      description:
        "Programa Asesoramiento Financiero (SEMIPRESENCIAL) Programa Sup. en Gestión Patrimonial (PRESENCIAL)",
    },
  },
  {
    image: "/imgs/efpa.jpg",
    isLink: false,
    information: {
      name: "IEF, Institut d'Estudis Financers",
      address: "Avda. Josep Tarradellas 123-127 08029 Barcelona",
      tel: "934 124 431",
      fax: "934 121 015",
      mail: "ief@iefweb.org",
      website: {
        label: "Visitar Website",
        link:
          "http://www.iefweb.org/es/cursos/master-y-posgrados/programa-superior-de-gestion-patrimonial/",
      },
      description:
        "Programa Asesoramiento Financiero (SEMIPRESENCIAL) Programa Sup. en Gestión Patrimonial (PRESENCIAL)",
    },
  },
]
