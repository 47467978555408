import React from "react"
import Proptypes from "prop-types"

export default function DownloadBtn(props) {
  const {
    className,
    text,
    link,
    reverse,
    variant,
    icon,
    onClick,
    blank = true,
  } = props

  const onClick_prevent = e => e.preventDefault()

  return (
    <div
      className={`DownloadBtn ${variant} ${className} ${
        reverse ? "reverse" : ""
      }`}
    >
      <a
        href={link ? link : ""}
        className="DownloadBtn-link"
        target={link && blank && "_blank"}
        rel={link && blank && "noopener noreferrer"}
        onClick={link || onClick ? onClick : onClick_prevent}
      >
        <i className={icon}></i>
        {text}
      </a>
    </div>
  )
}

DownloadBtn.defaultProps = {
  className: "",
  variant: "",
  text: "",
  link: "",
  icon: "fas fa-file-alt",
}

DownloadBtn.propTypes = {
  className: Proptypes.string,
  variant: Proptypes.oneOf(["dark", "bigIcon", "noUnderline"]),
  text: Proptypes.string,
  link: Proptypes.string,
  icon: Proptypes.string,
  reverse: Proptypes.bool,
  blank: Proptypes.bool,
  onClick: Proptypes.func,
}
